import { MediaStatsReportSummary } from "@azure/communication-calling";
import { AppConfig, getConfig } from "config/appConfig";


enum MeetingStatus {
    VMMetadataProvided = "VMMetadataProvided",
    Waiting = "Waiting",
    LinkProvided = "LinkProvided",
    PatientJoined = "PatientJoined",
    PatientWaitingInLobby = "PatientWaitingInLobby",    
    PatientReceivedMeetingLink = "PatientReceivedMeetingLink",
    CallFailed = "CallFailed",
    ProviderNoShow = "ProviderNoShow",
    CallEnded = "CallEnded"
}

interface DissatisfactionDetail {
    DetailType?: string;
    Detail?: string;
}

interface MeetingRating {
    MeetingRatingId?: number;
    PlatformStars?: number;
}

interface SaveMeetingRatingRequest {
    MeetingRating?: MeetingRating;
    DissatisfactionDetails?: DissatisfactionDetail[];
}

interface UpdateMeetingStatusRequest {
    Status: MeetingStatus|string;
}

interface MeetingLink {
    url: string;
}

interface ACSInfo {
    token: { token: string; expiresOn: Date; }

    user: { id: string; };

    acsUri: string;
}

interface TokenClaims {
    MeetingInfoId?: string;
    MeetingParticipantId?: string;
    VisitId?: string;
    DisplayName?: string;
    CallbackUri?: string;
    UserCode?: string;
    FirstJoin?: string;
    Brand?: string;
    exp?: string;
    ScheduledStartTime?: string;
}

class TelehealthClient {
    private baseUrl: string;
    private loginBaseUrl: string;
    private token: string;

    public TokenClaims?: TokenClaims;


    private userCode: string;
    private isMultiTouch: boolean;
    private participantName: string;
    private tokenLastUpdated: Date | null;

    constructor() {
        this.baseUrl = '';
        this.loginBaseUrl = '';
        this.token = '';


        this.userCode = '';
        this.isMultiTouch = false
        this.participantName = '';
        this.tokenLastUpdated = null;

    }

    public async initializeToken(code: string, isMultiTouch: boolean, participantName: string) {
        try {
            const Config: AppConfig = await getConfig();
            this.baseUrl = Config.PublicBaseUri;
            this.loginBaseUrl = Config.LoginBaseUri;

            this.userCode = code;
            this.isMultiTouch = isMultiTouch;
            this.participantName = participantName;

            this.token = await this.getTokenFromCode(code, isMultiTouch, participantName);
            this.TokenClaims = JSON.parse(atob(this.token.split('.')[1]));
            this.tokenLastUpdated = new Date();
            console.log('Token initialized successfully');
        } catch (error) {
            console.error('Failed to initialize token:', error);
            throw error;
        }
    }

    private async fetchWithAuth(url: string, options: RequestInit): Promise<Response> {
        options.headers = {
            ...options.headers,
            'Authorization': `Bearer ${this.token}`,
            'Content-Type': 'application/json'
        };
        //options.credentials='include'
        const response = await fetch(this.baseUrl + url, options);


        if (!response.ok) {
            const error = await response.text();
            console.error(`HTTP error! status: ${response.status}, message: ${error}, url: ${url}, options: ${JSON.stringify(options)}`);

            const now = new Date();
            const differenceInASeconds = (now.getTime() - (this.tokenLastUpdated ? this.tokenLastUpdated?.getTime() : 0)) / 1000

            if (response.status == 401 && differenceInASeconds > 30) {
                //when request is unauthorized and token was not refreshed in last 30 seconds
                
                console.log('Trying to refresh the token');
                await this.initializeToken(this.userCode, this.isMultiTouch, this.participantName);
                return this.fetchWithAuth(url, options);
            }
            else {
                throw new Error(`HTTP error! status: ${response.status}, message: ${error}`);
            }
        }
        return response;
    }

    private async fetchJsonWithAuth<T>(url: string, options: RequestInit): Promise<T> {
        const response = await this.fetchWithAuth(url, options);
        return response.json();
    }

    public async getACSToken(): Promise<ACSInfo> {
        return this.fetchJsonWithAuth<ACSInfo>('/Telehealth/GetACSToken', { method: 'GET' });
    }

    public async getTeamsMeetingLink(): Promise<string> {
        const data = await this.fetchJsonWithAuth<MeetingLink>('/Telehealth/GetTeamsMeetingLink', { method: 'GET' });
        return data.url;
    }

    public async postMeetingStatus(request: UpdateMeetingStatusRequest): Promise<void> {
        await this.fetchWithAuth('/Telehealth/PostMeetingStatus', {
            method: 'POST',
            body: JSON.stringify(request)
        });
    }

    public async postChatUtilized(): Promise<void> {
        await this.fetchWithAuth('/Telehealth/IndicateChat', {
            method: 'POST',
            body: ''
        });
    }

    public async postMeetingDiagnostics(summary: MediaStatsReportSummary) {
        await this.fetchWithAuth('/Telehealth/SaveMeetingDiagnostics', {
            method: 'POST',
            body: JSON.stringify(summary) //we get reliable stats for audio but not so much for video, still fine for giving us link quality information
        });
    }

    public async postDeviceCheckComplete() {
        await this.fetchWithAuth('/Telehealth/DeviceCheckComplete', {
            method: 'POST',
            body: ""
        });
    }

    //IncrementRetry
    //HardwareTrouble

    public async postIncrementRetry() {
        await this.fetchWithAuth('/Telehealth/IncrementRetry', {
            method: 'POST',
            body: ""
        });
    }

    public async postHardwareTrouble() {
        await this.fetchWithAuth('/Telehealth/HardwareTrouble', {
            method: 'POST',
            body: ""
        });
    }

    public async saveMeetingRating(request: SaveMeetingRatingRequest): Promise<void> {
        await this.fetchWithAuth('/Telehealth/SaveMeetingRating', {
            method: 'POST',
            body: JSON.stringify(request)
        });
    }

    private async getTokenFromCode(code: string, isMultiTouch = false, participantName: string = ""): Promise<string> {
        const Config: AppConfig = await getConfig();
        this.baseUrl = Config.PublicBaseUri;
        this.loginBaseUrl = Config.LoginBaseUri;

        const response = await fetch(`${this.loginBaseUrl}/Telehealth/GetTokenFromCode/${code}?isMultiTouch=${isMultiTouch}&participantName=${participantName}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include'
        });
        if (!response.ok) {            
            if (response.status == 404) {
                console.error(`Code not found! code: ${code}`);
                throw new Error('Code not found! code: ${code}');
            }
            else if(response.status == 410){
                let json = await response.json();
                throw new Error(`status: ${response.status}, message: ${json.detail}`);
            }
          
            let json = await response.json();
            console.error(`HTTP error! status: ${response.status}, message: ${json.detail}, code: ${code}`);
            throw new Error(`HTTP error! status: ${response.status}, message: ${json.detail}`);
        }
        return response.text();
    }

    public async getBrandFromCode(code: string): Promise<string> {
        
        const Config: AppConfig = await getConfig();
        this.baseUrl = Config.PublicBaseUri;
        this.loginBaseUrl = Config.LoginBaseUri;
        const response = await fetch(`${this.loginBaseUrl}/Telehealth/GetBrandFromCode/${code}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        if (!response.ok) {
            if (response.status == 404) {
                console.error(`Code not found! code: ${code}`);
                throw new Error('Code not found! code: ${code}');
            }
            let json = await response.json();
            console.error(`HTTP error! status: ${response.status}, message: ${json.detail}, code: ${code}`);
            throw new Error(`HTTP error! status: ${response.status}, message: ${json.detail}`);
        }
        return response.text();
    }
}

export { TelehealthClient, MeetingStatus };